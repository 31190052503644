import { DatePicker } from "@mui/lab";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { Field, FieldArray } from "formik";
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";
import SFIcon from "../SFIcon";
import FileUpload from "./fields/FileUpload";
import SFAutocomplete from "./SFAutocomplete";
import SFError from "./SFError";

export enum FieldType {
  Date,
  Text,
  LongText,
  Number,
  StaticSelect,
  DynamicSelect,
  Array,
  FileUpload,
  Password,
}

export type TSFFormItem = {
  name: string;
  type: FieldType;
  id?: string;
  label?: string;
  value?: any;
  handleChange?: any;
  handleBlur?: any;
  validation?: any;
  error?: any;
  touched?: boolean;
  setFieldValue?: any;
  inputProps?: any;
  reduxObjectName?: any;
  options?: any;
  fields?: TSFFormItem[];
  onChangeHandler?: any;
  fileInfoText?: any;
};

function RenderFormItem(props: TSFFormItem) {
  const {
    type,
    value,
    handleChange,
    error,
    touched,
    handleBlur,
    setFieldValue,
    name,
    label,
    id,
    inputProps,
    reduxObjectName,
    options,
    fields,
    fileInfoText,
  } = props;
  const items = useSelector(
    (state: any) => (state[reduxObjectName || name] || {}).items || []
  );
  switch (type) {
    case FieldType.Array:
      return (
        <>
          <Typography variant="h6">{label || sentenceCase(name)}</Typography>
          <FieldArray
            key={`${name}`}
            name={name}
            render={(arrayHelpers: any) => {
              return (
                <div>
                  {value.map((singleObject, index) => {
                    return (
                      <Card
                        variant="outlined"
                        className="p-4 mb-4"
                        key={`${name}-${index}`}
                      >
                        <div>
                          {fields &&
                            fields.map((field) => {
                              return (
                                <SFFormItem
                                  name={`${name}[${index}][${field.name}]`}
                                  label={field.label}
                                  type={field.type}
                                  key={`${name}[${index}][${field.name}]`}
                                  value={value[index][field.name]}
                                  handleChange={handleChange}
                                  error={
                                    ((error || [])[index] || {})[field.name]
                                  }
                                  touched={!!touched}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  inputProps={field.inputProps}
                                  options={field.options}
                                  reduxObjectName={field.reduxObjectName}
                                />
                              );
                            })}

                          <SFIcon
                            icon={"remove_circle"}
                            onClick={() => arrayHelpers.remove(index)}
                          ></SFIcon>
                        </div>
                      </Card>
                    );
                  })}
                  <SFIcon
                    icon={"add_circle"}
                    onClick={() =>
                      arrayHelpers.push(
                        fields?.reduce((pv, cv) => {
                          pv[cv.name] = cv.value;
                          return pv;
                        }, {})
                      )
                    }
                  ></SFIcon>
                </div>
              );
            }}
          />
        </>
      );
    case FieldType.Date:
      return (
        <>
          <DatePicker
            key={name}
            label={label || sentenceCase(name)}
            value={value}
            onChange={(value) => {
              return setFieldValue(
                name,
                moment(value).format("YYYY-MM-DD"),
                true
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={id || name}
                variant="standard"
                fullWidth
                margin="dense"
              />
            )}
          />
          <SFError>{error && touched && error}</SFError>
        </>
      );

    case FieldType.StaticSelect:
      return (
        <>
          <FormControl fullWidth variant="standard">
            <InputLabel
              style={{ color: "black" }}
              id="demo-simple-select-label"
            >
              {label || sentenceCase(name)}
            </InputLabel>
            <Select
              key={name}
              displayEmpty
              id={id || name}
              name={name}
              fullWidth
              classes={{ select: "py-8" }}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error && touched && error}
            >
              {options.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <SFError>{error && touched && error}</SFError>
          </FormControl>
        </>
      );
    case FieldType.DynamicSelect:
      return (
        <>
          <FormControl fullWidth variant="standard">
            <SFAutocomplete {...props} items={items}></SFAutocomplete>
            <SFError>{error && touched && error}</SFError>
          </FormControl>
        </>
      );
    case FieldType.Text:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </>
      );
    case FieldType.LongText:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            multiline
            maxRows={5}
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={(error && touched && error) || (value && value.length)}
          />
        </>
      );
    case FieldType.Number:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            name={name}
            id={id || name}
            label={label || sentenceCase(name)}
            type="number"
            value={value}
            fullWidth
            variant="standard"
            onChange={handleChange}
            inputProps={inputProps}
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
          />
        </>
      );
    case FieldType.Password:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="password"
            fullWidth
            variant="standard"
            value={value}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </>
      );

    case FieldType.FileUpload:
      return (
        <>
          <FileUpload {...props} />
        </>
      );
    default:
      return <SFError>Unknown field</SFError>;
  }
}

function SFFormItem(props: TSFFormItem) {
  return (
    <>
      <RenderFormItem {...props}></RenderFormItem>
    </>
  );
}

export default SFFormItem;

import withRouter from "@flux/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/withAppProviders";
import { fetchTraining, selectModel } from "./store/trainingSlice";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";

function TrainingTable(props) {
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.training.items);
  const gridRef = useRef<AgGridReact<any>>(null);


  useEffect(() => {
    dispatch(fetchTraining());
  }, [dispatch]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "id",
      filter: false,
      cellRenderer: ((_) => {
        const actionButtons = [
          {
            click: (id, { is_selected }) => {
              if (!is_selected) {
                is_selected = true;
                dispatch(selectModel({ id, is_selected }));
              }
            },
            title: "Status",
          },
        ];
        return ActionButtonRenderer(actionButtons);
      })(),
      headerName: `Selected Model`,
    },
    { field: "name", headerName: "Model name" },
    { field: "user", headerName: "Trained By" },
    {
      field: "accuracy",
      headerName: "Accuracy"
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

TrainingTable.propTypes = {};

export default withRouter(TrainingTable);

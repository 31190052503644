import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useState } from 'react';
import FluxPageSimpleSidebarContent from './FluxPageSimpleSidebarContent';

function FluxPageSimpleSidebar(props, ref) {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleSidebar: handleToggleDrawer,
  }));

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Hidden lgUp={props.variant === 'permanent'}>
        <SwipeableDrawer
          variant="temporary"
          anchor={props.position}
          open={isOpen}
          onOpen={(ev) => {}}
          onClose={(ev) => handleToggleDrawer()}
          disableSwipeToOpen
          classes={{
            root: clsx('FluxPageSimple-sidebarWrapper', props.variant),
            paper: clsx(
              'FluxPageSimple-sidebar',
              props.variant,
              props.position === 'left'
                ? 'FluxPageSimple-leftSidebar'
                : 'FluxPageSimple-rightSidebar'
            ),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          container={props.rootRef.current}
          BackdropProps={{
            classes: {
              root: 'FluxPageSimple-backdrop',
            },
          }}
          style={{ position: 'absolute' }}
        >
          <FluxPageSimpleSidebarContent {...props} />
        </SwipeableDrawer>
      </Hidden>
      {props.variant === 'permanent' && (
        <Hidden lgDown>
          <Drawer
            variant="permanent"
            className={clsx('FluxPageSimple-sidebarWrapper', props.variant)}
            open={isOpen}
            classes={{
              paper: clsx(
                'FluxPageSimple-sidebar',
                props.variant,
                props.position === 'left'
                  ? 'FluxPageSimple-leftSidebar'
                  : 'FluxPageSimple-rightSidebar'
              ),
            }}
          >
            <FluxPageSimpleSidebarContent {...props} />
          </Drawer>
        </Hidden>
      )}
    </>
  );
}

export default forwardRef(FluxPageSimpleSidebar);

import { useSelector } from "react-redux";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { selectContrastMainTheme } from "app/store/flux/settingsSlice";
import clsx from "clsx";
import { DefaultTheme } from "@mui/system";

function FluxPageSimpleHeader(props) {
  const theme = useTheme();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  ) as DefaultTheme;

  return (
    <div className={clsx("FluxPageSimple-header")}>
      {props.header && (
        <ThemeProvider theme={contrastTheme}>{props.header}</ThemeProvider>
      )}
    </div>
  );
}

export default FluxPageSimpleHeader;

import { RouteConfig } from "app/shared/types";
import Training from "./training";

const TrainingrouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'training-results',
            element: <Training />,
            permissions: ['training_read']
        },
    ],
};

export default TrainingrouteConfig;
import { RouteConfig } from "app/shared/types";
import Users from "./users";

const UsersrouteConfig: RouteConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'users',
            element: <Users />,
            permissions: ['user_read']
        },
    ],
};

export default UsersrouteConfig;
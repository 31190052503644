import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AnyAction } from "@reduxjs/toolkit";
import { updateUserShortcuts } from "app/login/store/userSlice";
import { selectFlatNavigation } from "app/store/flux/navigationSlice";
import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FluxIcon from "../FluxIcon/FluxIcon";
import PropTypes from "prop-types";

function FluxShortcuts(props) {
  const dispatch = useDispatch();
  const shortcuts = (useSelector<any>(({ auth }) => auth.user.shortcuts) ||
    []) as any;
  const navigation = useSelector(selectFlatNavigation) as any;

  const searchInputRef = useRef(null);
  const [addMenu, setAddMenu] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<any>(null);
  const shortcutItems = shortcuts
    ? shortcuts.map((id) => navigation.find((item: any) => item.id === id))
    : [];

  function addMenuClick(event) {
    setAddMenu(event.currentTarget);
  }

  function addMenuClose() {
    setAddMenu(null);
  }

  function search(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && navigation) {
      setSearchResults(
        navigation.filter((item) =>
          item.title.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
      return;
    }
    setSearchResults(null);
  }

  function toggleInShortcuts(id) {
    let newShortcuts = [...shortcuts];
    newShortcuts = newShortcuts.includes(id)
      ? newShortcuts.filter((_id) => id !== _id)
      : [...newShortcuts, id];
    dispatch(updateUserShortcuts(newShortcuts) as any);
  }

  function ShortcutMenuItem({ item, onToggle }) {
    return (
      <Link to={item.url} role="button">
        <MenuItem key={item.id}>
          <ListItemIcon className="min-w-40">
            {item.icon ? (
              <FluxIcon icon={item.icon}></FluxIcon>
            ) : (
              <span className="text-20 font-semibold uppercase text-center">
                {item.title[0]}
              </span>
            )}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          <IconButton
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              onToggle(item.id);
            }}
            size="large"
          >
            {/* <Icon color="action">{shortcuts.includes(item.id) ? 'star' : 'star_border'}</Icon> */}
            <FluxIcon
              icon={shortcuts.includes(item.id) ? "star" : "star_border"}
            ></FluxIcon>
          </IconButton>
        </MenuItem>
      </Link>
    );
  }
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, scale: 0.6 },
    show: { opacity: 1, scale: 1 },
  };

  return (
    <div
      className={clsx(
        "flex flex-1",
        props.variant === "vertical" && "flex-col grow-0 shrink",
        props.className
      )}
    >
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className={clsx(
          "flex flex-1",
          props.variant === "vertical" && "flex-col"
        )}
      >
        {shortcutItems.map(
          (_item) =>
            _item && (
              <Link to={_item.url} key={_item.id} role="button">
                <Tooltip
                  title={_item.title}
                  placement={props.variant === "horizontal" ? "bottom" : "left"}
                >
                  <IconButton
                    className="w-40 h-40 p-0"
                    component={motion.div}
                    variants={item}
                    size="large"
                  >
                    {_item.icon ? (
                      <FluxIcon icon={_item.icon}></FluxIcon>
                    ) : (
                      <span className="text-20 font-semibold uppercase">
                        {_item.title[0]}
                      </span>
                    )}
                  </IconButton>
                </Tooltip>
              </Link>
            )
        )}

        <Tooltip
          title="Click to add/remove shortcut"
          placement={props.variant === "horizontal" ? "bottom" : "left"}
        >
          <IconButton
            component={motion.div}
            variants={item}
            className="w-40 h-40 p-0"
            // aria-owns={addMenu ? 'add-menu' : null}
            aria-haspopup="true"
            onClick={addMenuClick}
            size="large"
          >
            <FluxIcon icon={"star"}></FluxIcon>
          </IconButton>
        </Tooltip>
      </motion.div>

      <Menu
        id="add-menu"
        anchorEl={addMenu}
        open={Boolean(addMenu)}
        onClose={addMenuClose}
        classes={{
          paper: "mt-48 min-w-256",
        }}
        TransitionProps={{
          onEntered: () => {
            (searchInputRef.current as any).focus();
          },
          onExited: () => {
            setSearchText("");
          },
        }}
      >
        <div className="p-16 pt-8">
          <Input
            inputRef={searchInputRef}
            value={searchText}
            onChange={search}
            placeholder="Search for an app or page"
            className=""
            fullWidth
            inputProps={{
              "aria-label": "Search",
            }}
            disableUnderline
          />
        </div>

        <Divider />

        {searchText.length !== 0 &&
          searchResults &&
          searchResults.map((_item) => (
            <ShortcutMenuItem
              key={_item.id}
              item={_item}
              onToggle={() => toggleInShortcuts(_item.id)}
            />
          ))}

        {searchText.length !== 0 && searchResults.length === 0 && (
          <Typography color="textSecondary" className="p-16 pb-8">
            No results..
          </Typography>
        )}

        {searchText.length === 0 &&
          shortcutItems.map(
            (_item) =>
              _item && (
                <ShortcutMenuItem
                  key={_item.id}
                  item={_item}
                  onToggle={() => toggleInShortcuts(_item.id)}
                />
              )
          )}
      </Menu>
    </div>
  );
}

FluxShortcuts.propTypes = {
  className: PropTypes.string,
};

FluxShortcuts.defaultProps = {
  variant: "horizontal",
};

export default memo(FluxShortcuts);

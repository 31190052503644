import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Root: any = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge, & > .logo-text": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <Link to={"/"} role="button">
        <div style={{ fontSize: "20px", color: "#009f6b", marginLeft: "10px" }}>
          Airflow SurgicalPrime<sup className="text-black">&trade;</sup>
        </div>
      </Link>
    </Root>
  );
}

export default Logo;

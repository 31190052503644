import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useAppDispatch } from "app/withAppProviders";
import MobileStepper from "@mui/material/MobileStepper";
import { Box, Button, Typography } from "@mui/material";
import { startShowForm } from "./store/predictAchSlice";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Coursel = (props) => {
  const dispatch = useAppDispatch();

  const { images, maxSteps, isDone, title } = props;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === maxSteps - 1) {
      dispatch(startShowForm(true));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Typography variant="h5" className="mb-4">
        {title}
      </Typography>
      <SwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.id}>
            <Typography variant="h6" className="mb-4">
              {step.text}
            </Typography>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.url}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={isDone ? false : activeStep === maxSteps - 1}
          >
            {isDone && activeStep === maxSteps - 1 ? "Done" : "Next"}
            {<KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {<KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
};

export default Coursel;

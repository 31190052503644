import FluxLoading from "@flux/core/FluxLoading";
import PropTypes from "prop-types";
import { Suspense } from "react";

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */ function FluxSuspense(props: any) {
  return (
    <Suspense fallback={<FluxLoading {...props.loadingProps} />}>
      {props.children}
    </Suspense>
  );
}

FluxSuspense.propTypes = {
  loadingProps: PropTypes.object,
};

FluxSuspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default FluxSuspense;

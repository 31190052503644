import { useEffect } from "react";
import { EDialogType, registerDialog } from "./main/apps/store/dialogSlice";
import { useAppDispatch } from "./withAppProviders";
import { useSelector } from "react-redux";
import TrainingAddDialog from "./main/apps/training/trainingAddDialog";
import UsersAddDialog from "./main/apps/users/usersAddDialog";

function Init({ children }) {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(({ auth }: any) => !!auth.user.id);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        registerDialog({
          key: EDialogType.TrainingAdd,
          open: false,
          title: "Add Training",
          desc: "",
        }),
      );
      dispatch(
        registerDialog({
          key: EDialogType.UsersAdd,
          open: false,
          title: "Add User",
          desc: "",
        }),
      );
    }
  }, []);

  return (
    <>
      {children}
      <TrainingAddDialog />
      <UsersAddDialog />
    </>
  );
}

export default Init;

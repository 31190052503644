import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import * as Yup from "yup";
import { useAppDispatch } from "app/withAppProviders";
import { TUser, createUser } from "./store/usersSlice";
import { EDialogType, closeDialog } from "../store/dialogSlice";
import { useSelector } from "react-redux";

function UsersAddForm() {
  const dispatch = useAppDispatch();

  const user: TUser =
    useSelector((state: any) => {
      return state.users?.items?.find((data) => data.id === state.users.editing)
    });

  const onSubmit = (w) => {
    dispatch(createUser({ user: w, id: user?.id }));
    dispatch(closeDialog({ key: EDialogType.UsersAdd }));
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "email",
        label: "Email",
        type: FieldType.Text,
        reduxObjectName: "email",
        validation: Yup.string()
          .required("Please enter email")
          .typeError("Required!"),
        value: user?.email
      },
      {
        name: "name",
        label: "Name",
        type: FieldType.Text,
        reduxObjectName: "name",
        validation: Yup.string()
          .required("Please enter name")
          .typeError("Required!"),
        value: user?.name
      },
      {
        name: "password",
        label: "Password",
        type: FieldType.Password,
        reduxObjectName: "password",
        validation: user ? null : Yup.string()
          .required("Please enter password")
          .typeError("Required!"),
      },
      {
        name: "user_type",
        label: "User Type",
        type: FieldType.StaticSelect,
        reduxObjectName: "user_type",
        validation: Yup.string()
          .required("Please select user type")
          .typeError("Required!"),
        options: [
          { id: "User", name: "User" },
          { id: "Admin", name: "Admin" },
        ],
        value: user?.user_type
      },
    ],
    resetFormOnSubmit: true,
  };

  return (
    <>
      <div style={{ margin: 8 }}>
        <SFForm {...form}></SFForm>
      </div>

    </>
  );
}

export default UsersAddForm;

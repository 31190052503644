import { useState } from "react";
import SignUp from "../signup/SignUp";
import LoginForm from "./LoginForm";

function Login() {
  const [selectedTab, setSelectedTab] = useState(0);

  function handleTabChange(value) {
    setSelectedTab(value);
  }

  return (
    <>
      {selectedTab === 0 ? (
        <LoginForm handleTabChange={handleTabChange}></LoginForm>
      ) : (
        <SignUp handleTabChange={handleTabChange}></SignUp>
      )}
    </>
  );
}

export default Login;

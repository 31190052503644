import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import { memo } from "react";
import _ from "@lodash";
import GlobalStyles from "@mui/material/GlobalStyles";
import FluxNavHorizontalLayout1 from "./horizontal/FluxNavHorizontalLayout1";
import FluxNavVerticalLayout1 from "./vertical/FluxNavVerticalLayout1";
import FluxNavVerticalLayout2 from "./vertical/FluxNavVerticalLayout2";
import FluxNavHorizontalCollapse from "./horizontal/types/FluxNavHorizontalCollapse";
import FluxNavHorizontalGroup from "./horizontal/types/FluxNavHorizontalGroup";
import FluxNavHorizontalItem from "./horizontal/types/FluxNavHorizontalItem";
import FluxNavHorizontalLink from "./horizontal/types/FluxNavHorizontalLink";
import FluxNavVerticalCollapse from "./vertical/types/FluxNavVerticalCollapse";
import FluxNavVerticalGroup from "./vertical/types/FluxNavVerticalGroup";
import FluxNavVerticalItem from "./vertical/types/FluxNavVerticalItem";
import FluxNavVerticalLink from "./vertical/types/FluxNavVerticalLink";
import { registerComponent } from "./FluxNavItem";

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      ".popper-navigation-list": {
        "& .flux-list-item": {
          padding: "8px 12px 8px 12px",
          height: 40,
          minHeight: 40,
          "& .flux-list-item-text": {
            padding: "0 0 0 8px",
          },
        },
        "&.dense": {
          "& .flux-list-item": {
            minHeight: 32,
            height: 32,
            "& .flux-list-item-text": {
              padding: "0 0 0 8px",
            },
          },
        },
      },
    })}
  />
);

/*
Register Flux Navigation Components
 */
registerComponent("vertical-group", FluxNavVerticalGroup);
registerComponent("vertical-collapse", FluxNavVerticalCollapse);
registerComponent("vertical-item", FluxNavVerticalItem);
registerComponent("vertical-link", FluxNavVerticalLink);
registerComponent("horizontal-group", FluxNavHorizontalGroup);
registerComponent("horizontal-collapse", FluxNavHorizontalCollapse);
registerComponent("horizontal-item", FluxNavHorizontalItem);
registerComponent("horizontal-link", FluxNavHorizontalLink);
registerComponent("vertical-divider", () => <Divider className="my-16" />);
registerComponent("horizontal-divider", () => <Divider className="my-16" />);

function FluxNavigation(props) {
  const options = _.pick(props, [
    "navigation",
    "layout",
    "active",
    "dense",
    "className",
    "onItemClick",
    "firstLevel",
    "selectedId",
  ]);
  if (props.navigation.length > 0) {
    return (
      <>
        {inputGlobalStyles}
        {props.layout === "horizontal" && (
          <FluxNavHorizontalLayout1 {...options} />
        )}
        {props.layout === "vertical" && <FluxNavVerticalLayout1 {...options} />}
        {props.layout === "vertical-2" && (
          <FluxNavVerticalLayout2 {...options} />
        )}
      </>
    );
  }
  return null;
}

FluxNavigation.propTypes = {
  navigation: PropTypes.array.isRequired,
  className: PropTypes.string,
  dense: PropTypes.string,
  active: PropTypes.bool,
  onItemClick: PropTypes.func,
  firstLevel: PropTypes.bool,
  selectedId: PropTypes.string,
};

FluxNavigation.defaultProps = {
  layout: "vertical",
};

export default memo(FluxNavigation);

import { TextField, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { sentenceCase } from "sentence-case";
import SFError from "../SFError";

export default function FileUpload(props) {
  const {
    type,
    value,
    handleChange,
    error,
    touched,
    handleBlur,
    setFieldValue,
    name,
    label,
    id,
    inputProps,
    reduxObjectName,
    options,
    fields,
    onChangeHandler,
    fileInfoText,
  } = props;

    const onFileChange = (file) => {
        // upload and get s3 url
        const evt = { target: { name: name, value: file.name } }
        handleChange(evt);
        onChangeHandler(file)
    }
    return <>
        <Typography variant="caption" display="block" gutterBottom>
            {label || sentenceCase(name)}
            <Tooltip placement="top" title={<Typography>{fileInfoText}</Typography>}>
                <IconButton>
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Typography>

      <TextField
        key={name}
        margin="dense"
        id={id || name}
        label={label || sentenceCase(name)}
        type="text"
        fullWidth
        value={value}
        name={name}
        onChange={handleChange}
        variant="standard"
        onBlur={handleBlur}
        hidden
          />
          
      <FileUploader
        handleChange={onFileChange}
        name={`fu_${name}`}
        types={["ZIP"]}
      />
          
      <SFError>{error && touched && error}</SFError>
    </>
  
}

import FluxSplashScreen from "@flux/core/FluxSplashScreen";
import { bindActionCreators } from "@reduxjs/toolkit";
import jwtService from "app/services/jwtService";
import { hideMessage, showMessage } from "app/store/flux/messageSlice";
import { connect } from "react-redux";
import { logoutUser, setUserData } from "app/login/store/userSlice";
import React from "react";

type AuthProps = {
  children: React.ReactNode;
};
type AuthState = {
  waitAuthCheck: boolean;
};

class Auth extends React.Component<AuthProps, AuthState> {
  props;
  state = {
    waitAuthCheck: true,
  };

  private jwtCheckPromise: Promise<any> | null = null;

  componentDidMount() {
    if (!this.jwtCheckPromise) {
      this.jwtCheckPromise = Promise.all([
        this.jwtCheck(),
        // new Promise((resolve, reject) => { setTimeout(() => resolve(null), 2000) })
      ]).then(() => {
        this.setState({ waitAuthCheck: false });
      });

      return this.jwtCheckPromise;
    }
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on("onAutoLogin", () => {
        console.log("Just logged in!");

        // this.props.showMessage({ message: 'Logging in with JWT' });

        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);

            resolve(null);

            // this.props.showMessage({ message: 'Logged in with JWT' });
          })
          .catch((error) => {
            this.props.showMessage({ message: error.message });

            resolve(null);
          });
      });

      jwtService.on("onAutoLogout", (message) => {
        if (message) {
          this.props.showMessage({ message });
        }
        this.props.logout();
        resolve(null);
      });

      jwtService.on("onNoAccessToken", () => {
        resolve(null);
      });

      jwtService.init();

      resolve(null);
    });

  render() {
    return this.state.waitAuthCheck ? (
      <FluxSplashScreen />
    ) : (
      <>{this.props.children}</>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);

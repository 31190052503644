import { amber, blue, green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "app/store/flux/messageSlice";

const StyledSnackbar: any = styled(Snackbar)(({ theme, variant }: any) => ({
  "& .FluxMessage-content": {
    ...(variant === "success" && {
      backgroundColor: green[600],
      color: "#FFFFFF",
    }),

    ...(variant === "error" && {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
    }),

    ...(variant === "info" && {
      backgroundColor: blue[600],
      color: "#FFFFFF",
    }),

    ...(variant === "warning" && {
      backgroundColor: amber[600],
      color: "#FFFFFF",
    }),
  },
}));

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

function FluxMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ flux }: any) => flux.message.state);
  const options = useSelector(({ flux }: any) => flux.message.options);

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage(null))}
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}
    >
      <SnackbarContent
        className="FluxMessage-content"
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <Icon color="inherit">{variantIcon[options.variant]}</Icon>
            )}
            <Typography className="mx-8">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage(null))}
            size="large"
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
}

export default memo(FluxMessage);

import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Typography,
} from "@mui/material";

type TDialogProps = {
  title?: string;
  desc?: string;
  state?: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  handleSuccess?: () => void;
  children?: any;
};

function SFDialog(props: TDialogProps) {
  const { title, state = false, desc, handleClose, children } = props;
  return (
    <Dialog open={state} onClose={handleClose} fullWidth maxWidth="sm">
      <AppBar
        style={{ backgroundColor: "#000", color: "#fff" }}
        position="static"
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>{desc}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleSuccess}>✔️ Add</Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default SFDialog;

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo } from 'react';

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    textAlign: "center"
  }
})(Typography);


function FluxSplashScreen() {
  return (
    <div id="flux-splash-screen">
      <div className="center">
        <div className="logo">
          {/* <img width="300" src="assets/images/logo.png" alt="logo" /> */}
          <WhiteTextTypography variant="h4" ></WhiteTextTypography>
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(FluxSplashScreen);

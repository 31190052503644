import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSettings } from "app/login/store/userSlice";
import { setDefaultSettings } from "app/store/flux/settingsSlice";

function SchemePreview({ theme, className, id, onSelect }: any) {
  const _theme = useTheme();
  const primaryColor = theme.palette.primary[500]
    ? theme.palette.primary[500]
    : theme.palette.primary.main;
  const primaryColorContrast = _theme.palette.getContrastText(primaryColor);
  const secondaryColor = theme.palette.secondary[500]
    ? theme.palette.secondary[500]
    : theme.palette.secondary.main;
  const secondaryColorContrast = _theme.palette.getContrastText(secondaryColor);
  const backgroundColor = theme.palette.background.default;
  const backgroundColorContrast = _theme.palette.getContrastText(
    theme.palette.background.default
  );
  const paperColor = theme.palette.background.paper;
  const paperColorContrast = _theme.palette.getContrastText(
    theme.palette.background.paper
  );

  return (
    <div className={clsx(className, "mb-8")}>
      <button
        className={clsx(
          "w-full text-left rounded-6 relative font-500 shadow hover:shadow-md transition-shadow cursor-pointer overflow-hidden"
        )}
        style={{
          backgroundColor,
          color: backgroundColorContrast,
        }}
        onClick={() => onSelect(id)}
        type="button"
      >
        <div
          className="w-full h-56 px-8 pt-8 relative"
          style={{
            backgroundColor: primaryColor,
            color: primaryColorContrast,
          }}
        >
          <span className="text-12 opacity-75">Header (Primary)</span>

          <div
            className="flex items-center justify-center w-20 h-20 rounded-full absolute bottom-0 right-0 -mb-10 shadow text-10 mr-4"
            style={{
              backgroundColor: secondaryColor,
              color: secondaryColorContrast,
            }}
          >
            <span className="opacity-75">S</span>
          </div>
        </div>
        <div className="pl-8 pr-28 -mt-24 w-full">
          <div
            className="w-full h-96 rounded-4 relative shadow p-8"
            style={{
              backgroundColor: paperColor,
              color: paperColorContrast,
            }}
          >
            <span className="text-12 opacity-75">Paper</span>
          </div>
        </div>

        <div className="px-8 py-8 w-full">
          <span className="text-12 opacity-75">Background</span>
        </div>
      </button>
      <Typography className="font-semibold w-full text-center mt-12">
        {id}
      </Typography>
    </div>
  );
}

function FluxThemeSchemes(props) {
  const dispatch = useDispatch();
  const user: any = useSelector(({ auth }: any) => auth.user);
  const themes = useSelector(({ flux }: any) => flux.settings.themes);
  const settings = useSelector(({ flux }: any) => flux.settings.current);

  function handleSchemeSelect(themeId) {
    const newSettings = {
      ...settings,
      theme: {
        main: themeId,
        navbar: themeId,
        toolbar: themeId,
        footer: themeId,
      },
    };

    if (user.permissions === "guest") {
      dispatch(setDefaultSettings(newSettings));
    } else {
      dispatch(updateUserSettings(newSettings) as any);
    }
  }

  return (
    <div>
      <div className="flex flex-wrap w-full -mx-8">
        {Object.entries(themes)
          .filter(
            ([key, val]) =>
              !(key === "mainThemeDark" || key === "mainThemeLight")
          )
          .map(([key, val]) => (
            <div key={key} className="w-1/2 p-8">
              <SchemePreview
                id={key}
                theme={val}
                onSelect={handleSchemeSelect}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default memo(FluxThemeSchemes);

import { fontWeight } from "@mui/system";

function EnvironmentTag() {
    const env = process.env.REACT_APP_ENV;

    return <>
        {
            env != 'prod' && <div style={{
                position: 'fixed',
                top: '32px',
                right: '-10px',
                width: '100px',
                height: '20px',
                textAlign: 'center',
                transform: 'rotate(45deg)',
                zIndex: 1000,
                backgroundColor: 'red',
                color: '#FFF',
                fontWeight: 'bold'
            }}>
                {env}
            </div>
        }
    </>
}

export default EnvironmentTag;
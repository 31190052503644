import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { getFiltersByTable } from './selectors/tableFilterSelector';

export type TQuickLink = {
    id?: number
    name: string,
    table: string,
    data?: any,
}

export type QuickLinkState = {
    items: TQuickLink[]
    editing: number | boolean,
    filter: { [key: string]: number },
    export: boolean,
    sidebar: boolean,
    lastUpdated: Moment | null
}

const initialState: QuickLinkState = {
    items: [],
    editing: false,
    filter: {},
    export: false,
    sidebar: false,
    lastUpdated: null,
};

export const fetchQuickLink = createAsyncThunk(
    'misc/quick-links',
    async () => {
        const response = await axios.get('/misc/quick-links');
        return response.data
    }
)

export const createQuickLink = createAsyncThunk(
    'misc/quick-links/create',
    async (quickLink: TQuickLink) => {
        const response = await axios.post(`/misc/quick-links`, quickLink);
        return response.data
    }
)

export const deleteQuickLink = createAsyncThunk(
    'misc/quick-links/delete',
    async (id: number) => {
        const response = await axios.delete(`/misc/quick-links/${id}`);
        return { id }
    }
)

const quickLinkSlice = createSlice({
    name: 'QuickLink',
    initialState,
    reducers: {
        startEditing: (state, action) => {
            state.editing = action.payload
        },
        stopEditing: (state) => {
            state.editing = false
        },
        startExport: (state) => {
            state.export = true
        },
        stopExport: (state) => {
            state.export = false
        },
        toggleSidebar: (state) => {
            state.sidebar = !state.sidebar
        }
    },

    extraReducers: {
        [`${createQuickLink.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            if (index >= 0) {
                state.items[index] = action.payload
            } else {
                state.items.push(action.payload);
            }
        },
        [`${fetchQuickLink.fulfilled}`]: (state, action) => {
            state.items = action.payload.data;
            state.lastUpdated = moment()
        },
        [`${deleteQuickLink.fulfilled}`]: (state, action) => {
            const index = state.items.findIndex(it => it.id === action.payload.id)
            state.items.splice(index, 1);
        }
    }
});

export const { startEditing, stopEditing, startExport, stopExport, toggleSidebar } = quickLinkSlice.actions;
export default quickLinkSlice.reducer;
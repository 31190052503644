import { Button, IconButton } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

type TActionButton = {
    click: (id: number, extras?: any) => void
    title: string
}
function ActionButtonRenderer(actionButtons: TActionButton | TActionButton[]) {
    let actions;
    if (!Array.isArray(actionButtons)) {
        actions = [actionButtons as TActionButton]
    } else {
        actions = actionButtons as TActionButton[]
    }
    return (props: ICellRendererParams) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        return (
            <>
                {actions.map((action, index) => {
                    switch (action.title) {
                        case "Delete":
                            return <IconButton aria-label={action.title} onClick={() => { action.click(cellValue, props.data) }} key={index}>
                                <DeleteIcon />
                            </IconButton>
                        case "Edit":
                            return <IconButton aria-label={action.title} onClick={() => { action.click(cellValue, props.data) }} key={index}>
                                <EditIcon />
                            </IconButton>
                        case "Status":
                            if (props.data.is_selected === true)
                                return (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="success"
                                        onClick={() => {
                                            action.click(cellValue, props.data);
                                        }}
                                        key={index}
                                    >
                                        {"Active"}
                                    </Button>
                                );
                            else
                                return (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            action.click(cellValue, props.data);
                                        }}
                                        key={index}
                                    >
                                        {"Select"}
                                    </Button>
                                );
                        default:
                            return <Button onClick={() => { action.click(cellValue, props.data) }} key={index}>{action.title}</Button>
                    }
                })}
            </>
        );
    };
}

export default ActionButtonRenderer;

import FluxShortcuts from "@flux/core/FluxShortcuts";
import FluxSidePanel from "@flux/core/FluxSidePanel";
import { memo } from "react";

function LeftSideLayout3() {
  return (
    <>
      <FluxSidePanel>
        <FluxShortcuts className="py-16 px-8" variant="vertical" />
      </FluxSidePanel>
    </>
  );
}

export default memo(LeftSideLayout3);

import SignUp from "app/signup/SignUp";
import Login from "./Login";

const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "signUp",
      element: <SignUp />,
    },
  ],
};

export default LoginConfig;

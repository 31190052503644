const navigationConfig = [
  {
    id: "airflow-calculation",
    title: "Airflow Calculation",
    type: "item",
    url: "airflow-calculation",
  },
  {
    id: "predict-ach",
    title: "Predict ACH",
    type: "item",
    url: "predict-ach",
  },
  {
    id: "training-results",
    title: "Training Results",
    type: "item",
    url: "training-results",
    permissions: ["training_read"],
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    url: "users",
    permissions: ["user_read"],
  },
];

export default navigationConfig;

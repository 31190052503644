import FluxUtils from "@flux/utils/FluxUtils";
import history from "@history";
import axios from "axios";
/* eslint-disable camelcase */

class JwtService extends FluxUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.emit(
              "onUnauthorizedAccess",
              "Session invalidated, Please re-login"
            );
            this.emit("onAutoLogout", "Session invalidated, Please re-login");
            this.setSession(null);
          }

          if (
            err.response.status === 403 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an forbidden response, logout the user
            // const { data: { error } } = err.response;
            // this.emit('onAutoLogout', error);
            // this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  signIn = (username, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const response = await axios.post("/login", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response) {
          this.setSession(response.data.data.token);
          resolve(response.data.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  signUp = (signupData: any) => {
    const data: any = {
      name: signupData.name,
      email: signupData.email,
      password: signupData.password,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/register", data);
        this.setSession(response.data);
        history.push("/login");
        resolve(response.data.message);
      } catch (errors: any) {
        reject(errors.response.detail.error);
      }
    });
  };

  signInWithToken = () => {
    return new Promise(async (resolve, reject) => {
      // try {
      //     const response = await axios
      //         .get('/user/signin-with-token');
      //     const { user } = response.data;
      //     resolve(user);
      // } catch (error) {
      //     this.logout();
      //     reject(new Error('Failed to login with token.'));
      // }
    });
  };

  setSession = (token: string | null) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  getAccessToken = () => {
    return window.localStorage.getItem("token");
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (accessToken) {
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      // this.emit('onAutoLogout', 'access_token expired');
    }
  };
}

const instance = new JwtService();

export default instance;

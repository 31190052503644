import { combineReducers } from "@reduxjs/toolkit";
import auth from "app/login/store";
import dialogSlice from "app/main/apps/store/dialogSlice";
import tableFilterSlice from "app/main/apps/store/tableFilterSlice";
import flux from "./flux";
import i18n from "./i18nSlice";
import enumSlice from "app/main/apps/store/enumSlice";
import usersSlice from "app/main/apps/users/store/usersSlice";
import quickLinkSlice from "app/main/apps/store/quickLinkSlice";
import trainingSlice from "app/main/apps/training/store/trainingSlice";
import predictAchSlice from "app/main/apps/predict-ach/store/predictAchSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    flux,
    i18n,
    enum: enumSlice,
    dialog: dialogSlice,
    tableFilter: tableFilterSlice,
    quickLinks: quickLinkSlice,
    predictAch: predictAchSlice,
    training: trainingSlice,
    users: usersSlice,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === "auth/user/userLoggedOut") {
    // state = undefined;
  }

  return combinedReducer(state, action as never);
};

export default createReducer;

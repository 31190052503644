import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/flux/messageSlice';

export type TUser = {
  id: number
  name: string
  email: string
  password: string
  user_type: string
}

export type TUserState = {
  items: TUser[];
  editing: number | boolean;
}

export const fetchUsers = createAsyncThunk(
  'user/fetch',
  async () => {
    const response = await axios.get('/users');
    return response.data;
  }
)

export const createUser = createAsyncThunk(
  'user/create',
  async ({ user, id }: { user: TUser, id: number }, { dispatch }) => {
    try {
      const url = id ? `/user/${id}` : '/user'
      const response = await axios.post(url, user);
      dispatch(
        showMessage({
          message: response.data.message,
          variant: 'success',
          autoHideDuration: 2000,
        })
      );

      dispatch(fetchUsers())
      return response.data.data;
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.detail.error,
          variant: 'error',
          autoHideDuration: 2000,
        })
      );
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.delete(`/user/${id}`);
      dispatch(
        showMessage({
          message: response.data.message,
          variant: 'success',
          autoHideDuration: 2000,
        })
      );

      dispatch(fetchUsers());
      return response.data;
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.detail.error,
          variant: 'error',
          autoHideDuration: 2000,
        })
      );
    }
  }
);

const initialState: TUserState = {
  items: [],
  editing: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
  },
  extraReducers: {
    [`${fetchUsers.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
    [`${createUser.fulfilled}`]: (state, action) => {
      state.items = action.payload;
    }
  }
}
);

export const {
  startEditing,
  stopEditing,
} = usersSlice.actions;

export default usersSlice.reducer;

import history from "@history";
import FluxUtils from "@flux/utils";
import AppContext from "app/AppContext";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

export default function FluxAuthorization(props) {
  const { children } = props;
  const location = useLocation();

  const appContext = useContext(AppContext);
  const { routes } = appContext as any;
  const [state, setState] = useState({
    accessGranted: false,
  });

  let userPermissions =
    useSelector(({ auth }: any) => auth.user.permissions) || [];
  const isLoggedIn = useSelector(({ auth }: any) => !!auth.user.id);

  useEffect(() => {
    const { pathname } = location;

    const publicPaths = ["/login", "/signUp"];

    const isPublicPath = publicPaths.some((it) =>
      it.includes(pathname.substring(1, 5))
    );

    if (isPublicPath && isLoggedIn) {
      setTimeout(() => history.push("/airflow-calculation"), 0);
    }

    if (!isPublicPath && !isLoggedIn) {
      setTimeout(() => history.push("/login"), 0);
    }

    const matchedRoutes = matchRoutes(routes, pathname);

    const matched: any = matchedRoutes ? matchedRoutes[0] : false;

    let accessGranted = false;
    if (matched.route.permissions || matched.route.permissions?.length === 0) {
      accessGranted = true;
    } else {
      accessGranted = matched
        ? FluxUtils.hasPermission(matched.route.permissions, userPermissions)
        : true;
    }

    setState({ accessGranted });
  }, [location.pathname, isLoggedIn]);

  return state.accessGranted ? <>{children}</> : null;
}

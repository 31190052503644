import Hidden from "@mui/material/Hidden";
import { styled, ThemeProvider } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import NavbarToggleFab from "app/flux-layouts/shared-components//NavbarToggleFab";
import { navbarCloseMobile } from "app/store/flux/navbarSlice";
import clsx from "clsx";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNavbarTheme } from "app/store/flux/settingsSlice";
import NavbarLayout3 from "./NavbarLayout3";
import NavbarMobileLayout3 from "./NavbarMobileLayout3";

const StyledSwipeableDrawer: any = styled(SwipeableDrawer)(({ theme }) => ({
  "& > .MuiDrawer-paper": {
    height: "100%",
    flexDirection: "column",
    flex: "1 1 auto",
    width: 280,
    minWidth: 280,
    transition: theme.transitions.create(["width", "min-width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

function NavbarWrapperLayout3(props) {
  const dispatch = useDispatch();
  const config = useSelector(
    ({ flux }: any) => flux.settings.current.layout.config
  );
  const navbarTheme = useSelector(selectNavbarTheme);
  const navbar = useSelector(({ flux }: any) => flux.navbar);

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <Hidden lgDown>
          <NavbarLayout3 className={clsx(props.className)} />
        </Hidden>

        <Hidden lgUp>
          <StyledSwipeableDrawer
            anchor="left"
            variant="temporary"
            open={navbar.mobileOpen}
            onClose={() => dispatch(navbarCloseMobile(null))}
            onOpen={() => {}}
            disableSwipeToOpen
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <NavbarMobileLayout3 />
          </StyledSwipeableDrawer>
        </Hidden>
      </ThemeProvider>
      {config.navbar.display && !config.toolbar.display && (
        <Hidden lgUp>
          <NavbarToggleFab />
        </Hidden>
      )}
    </>
  );
}

export default memo(NavbarWrapperLayout3);

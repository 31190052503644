import { Formik } from "formik";
import SFFormItem, { TSFFormItem } from "./SFFormItem";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import * as Yup from "yup";

export type TSFForm = {
  fields: TSFFormItem[];
  onSubmit: (values) => void;
  resetFormOnSubmit?: boolean;
  onChangeHandler?: any;
};

function buildValidationSchema(fields) {
  const rtn = fields.reduce((pv, cv) => {
    if (cv.validation) pv[cv.name] = cv.validation;

    return pv;
  }, {});
  return Yup.object().shape(rtn);
}

function buildInitValues(fields) {
  const rtn = fields.reduce((pv, cv) => {
    pv[cv.name] = cv.value;
    return pv;
  }, {});
  return rtn;
}

function SFForm(props: TSFForm) {
  const {
    fields,
    onChangeHandler,
    onSubmit,
    resetFormOnSubmit = false,
  } = props;

  return (
    <>
      <Formik
        initialValues={buildInitValues(fields)}
        validationSchema={buildValidationSchema(fields)}
        onSubmit={async (values, actions) => {
          await onSubmit(values);
          if (resetFormOnSubmit) {
            actions.resetForm();
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => {
          return (
            <form style={{ color: "black" }} onSubmit={handleSubmit}>
              {fields &&
                fields.map((field) => (
                  <SFFormItem
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    key={field.name}
                    value={values[field.name]}
                    handleChange={handleChange}
                    error={errors[field.name]}
                    touched={!!touched[field.name]}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    inputProps={field.inputProps}
                    options={field.options}
                    reduxObjectName={field.reduxObjectName}
                    fields={field.fields}
                    onChangeHandler={field.onChangeHandler}
                    fileInfoText={field.fileInfoText}
                  />
                ))}

              <Stack
                spacing={2}
                direction="row"
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Stack>
              {/* <DisplayFormikState values={values} errors={errors} touched={touched} isSubmitting={isSubmitting} /> */}
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default SFForm;

import { styled } from "@mui/material/styles";
import FluxPageSimple from "@flux/core/FluxPageSimple/FluxPageSimple";
import TrainingHead from "./trainingHead";
import TrainingTable from "./trainingTable";

const Root = styled(FluxPageSimple)(({ theme }) => ({
  "& .FluxPageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 100,
      height: 100,
    },
  },
  "& .FluxPageSimple-content": {
    display: "flex",
  },

  "& .FluxPageSimple-contentCard": {
    overflow: "hidden",
    marginLeft: "30%",
    marginTop: "1%",
    borderRadius: "20px",
    minHeight: "20px",
  },
}));

function Training() {
  return (
    <Root
      header={<TrainingHead />}
      content={<TrainingTable />} innerScroll
    />
  );
}

export default Training;

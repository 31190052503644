import { styled } from "@mui/material/styles";
import FluxPageSimple from "@flux/core/FluxPageSimple/FluxPageSimple";
import PredictAchHead from "./predictAchHead";
import PredictAchForm from "./predictAchForm";

const Root = styled(FluxPageSimple)(({ theme }) => ({
  "& .FluxPageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 100,
      height: 100,
    },
  },
  "& .FluxPageSimple-content": {},

  "& .FluxPageSimple-contentCard": {
    overflow: "hidden",
    marginLeft: "30%",
    marginTop: "1%",
    borderRadius: "20px",
    minHeight: "20px",
  },
}));

function PredictAch() {
  return <Root header={<PredictAchHead />} content={<PredictAchForm />} />;
}

export default PredictAch;

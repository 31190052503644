import FluxIcon from "@flux/core/FluxIcon/FluxIcon";
import NavLinkAdapter from "@flux/core/NavLinkAdapter";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha, styled } from "@mui/material/styles";
import SFIcon from "app/shared/components/SFIcon";
import { ESFIconType } from "app/shared/components/SFIcon/SFIcon";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import FluxNavBadge from "../../FluxNavBadge";
import FluxNavItem from "../../FluxNavItem";

const Root: any = styled("ul")(({ theme, ...props }: any) => ({
  padding: 0,
  "&.open": {},
  "& > .flux-list-item": {
    height: 40,
    width: "100%",
    borderRadius: "6px",
    margin: "0 0 4px 0",
    paddingRight: 12,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    color: alpha(theme.palette.text.primary, 0.7),
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "& > .flux-list-item-icon": {
      marginRight: 12,
      color: "inherit",
    },
  },
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url)
    ) {
      return true;
    }
  }

  return false;
}

function FluxNavVerticalCollapse(props) {
  const [open, setOpen] = useState(() =>
    needsToBeOpened(props.location, props.item)
  );
  const { item, nestedLevel, onItemClick } = props;
  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;

  const location = useLocation();

  useEffect(() => {
    if (needsToBeOpened(location, props.item)) {
      if (!open) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [location, props.item]);

  return useMemo(
    () => (
      <Root className={clsx(open && "open")} itempadding={itempadding}>
        <ListItem
          // component={item.url ? NavLinkAdapter : 'li'}
          // button
          className="flux-list-item"
          onClick={() => setOpen(!open)}
          // to={item.url}
          // end={item.end}
          role="button"
        >
          {item.icon && (
            // <FluxIcon icon={item.icon}></FluxIcon>
            <SFIcon icon={item.icon} type={ESFIconType.Icon} />
          )}

          <ListItemText
            className="flux-list-item-text"
            primary={item.title}
            classes={{ primary: "text-13 font-medium" }}
          />

          {item.badge && <FluxNavBadge className="mx-4" badge={item.badge} />}

          <IconButton
            disableRipple
            className="w-40 h-40 -mx-12 p-0 focus:bg-transparent hover:bg-transparent"
            onClick={(ev) => ev.preventDefault()}
            size="large"
          >
            <SFIcon
              icon={open ? "expand_less" : "expand_more"}
              type={ESFIconType.Icon}
            />
          </IconButton>
        </ListItem>

        {item.children && (
          <Collapse in={open} className="collapse-children">
            {item.children.map((_item) => (
              <FluxNavItem
                key={_item.id}
                type={`vertical-${_item.type}`}
                item={_item}
                nestedLevel={nestedLevel + 1}
                onItemClick={onItemClick}
              />
            ))}
          </Collapse>
        )}
      </Root>
    ),
    [
      item.badge,
      item.children,
      item.icon,
      // item.iconClass,
      item.title,
      item.url,
      item.end,
      itempadding,
      nestedLevel,
      onItemClick,
      open,
    ]
  );
}

FluxNavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
FluxNavVerticalCollapse.defaultProps = {};

const NavVerticalCollapse = FluxNavVerticalCollapse;

export default NavVerticalCollapse;

// import { stopEditing } from "app/main/apps/pulse/store/worklogSlice";
import { closeDialog } from "app/main/apps/store/dialogSlice";
import { useAppDispatch } from "app/withAppProviders";
import { useSelector } from "react-redux";
import SFDialog from "../SFDialog";

const withDialog = (key, OriginalComponent) => {
    function HOCDialog(props) {
        const dispatch = useAppDispatch()
        const handleClose = () => {
            dispatch(closeDialog({
                key
            }))
        };

        const dialog = useSelector((state: any) => (state.dialog.items || []).find(d => d.key === key)) || {}

        return <>
            <SFDialog title={dialog.title} desc={dialog.desc}
                state={dialog.open}
                handleClose={handleClose}
            >
                <OriginalComponent extras={dialog.extras} />
            </SFDialog>
        </>
    }
    return HOCDialog;
};
export default withDialog;
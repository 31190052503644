import { createSlice } from "@reduxjs/toolkit";
import jwtService from "app/services/jwtService";
import { showMessage } from "app/store/flux/messageSlice";

export const signupUser =
  ({ userInfo }) =>
  async (dispatch, state) => {
    try {
      const user: any = await jwtService.signUp(userInfo);
      dispatch(
        showMessage({
          message: user,
          variant: "success",
        })
      );
    } catch (user: any) {
      dispatch(
        showMessage({
          message: user,
          variant: "error",
        })
      );
    }
  };

const initialState = {
  success: false,
  errors: [],
};

const signupSlice = createSlice({
  name: "auth/signup",
  initialState,
  reducers: {
    signupSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    signupError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { signupSuccess, signupError } = signupSlice.actions;

export default signupSlice.reducer;

import NavLinkAdapter from "@flux/core/NavLinkAdapter";
import { styled, useTheme } from "@mui/material/styles";
import { useDebounce } from "@flux/hooks";
import Grow from "@mui/material/Grow";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import clsx from "clsx";
import PropTypes from "prop-types";
import { memo, useState, useMemo } from "react";
import * as ReactDOM from "react-dom";
import { Manager, Popper, Reference } from "react-popper";
import withRouter from "@flux/core/withRouter";
import FluxNavBadge from "../../FluxNavBadge";
import FluxNavItem from "../../FluxNavItem";

const StyledListItem: any = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  minHeight: 48,
  "&.active, &.active:hover, &.active:focus": {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,

    "&.open": {
      backgroundColor: "rgba(0,0,0,.08)",
    },

    "& > .flux-list-item-text": {
      padding: "0 0 0 16px",
    },

    "& .flux-list-item-icon": {
      color: "inherit",
    },
  },
}));

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url)
    ) {
      return true;
    }
  }

  return false;
}

function FluxNavHorizontalCollapse(props) {
  const [opened, setOpened] = useState(false);
  const { item, nestedLevel, dense } = props;
  const theme = useTheme();

  const handleToggle = useDebounce(
    (open) => {
      setOpened(open);
    },
    150,
    null
  );

  return useMemo(
    () => (
      <ul className="relative px-0">
        <Manager>
          <>
            <Reference>
              {({ ref }) => (
                <div ref={ref}>
                  <StyledListItem
                    button
                    className={clsx(
                      "flux-list-item",
                      opened && "open",
                      isUrlInChildren(item, props.location.pathname) && "active"
                    )}
                    onMouseEnter={() => handleToggle(true)}
                    onMouseLeave={() => handleToggle(false)}
                    aria-owns={opened ? "menu-flux-list-grow" : null}
                    aria-haspopup="true"
                    component={item.url ? NavLinkAdapter : "li"}
                    to={item.url}
                    end={item.end}
                    role="button"
                  >
                    {item.icon && (
                      <Icon
                        color="action"
                        className={clsx(
                          "flux-list-item-icon text-16 shrink-0",
                          item.iconClass
                        )}
                      >
                        {item.icon}
                      </Icon>
                    )}

                    <ListItemText
                      className="flux-list-item-text"
                      primary={item.title}
                      classes={{ primary: "text-13" }}
                    />

                    {item.badge && (
                      <FluxNavBadge className="mx-4" badge={item.badge} />
                    )}
                    <IconButton
                      disableRipple
                      className="w-16 h-16 ltr:ml-4 rtl:mr-4 p-0"
                      color="inherit"
                      size="large"
                    >
                      <Icon className="text-16 arrow-icon">
                        {theme.direction === "ltr"
                          ? "keyboard_arrow_right"
                          : "keyboard_arrow_left"}
                      </Icon>
                    </IconButton>
                  </StyledListItem>
                </div>
              )}
            </Reference>
            {ReactDOM.createPortal(
              <Popper
                placement={theme.direction === "ltr" ? "right" : "left"}
              // eventsEnabled={opened}
              // positionFixed
              >
                {({ ref, style, placement, arrowProps }) =>
                  opened && (
                    <div
                      ref={ref}
                      style={{
                        ...style,
                        zIndex: 999 + nestedLevel + 1,
                      }}
                      data-placement={placement}
                      className={clsx("z-999", !opened && "pointer-events-none")}
                    >
                      <Grow
                        in={opened}
                        id="menu-flux-list-grow"
                        style={{ transformOrigin: "0 0 0" }}
                      >
                        <Paper
                          className="rounded-8"
                          onMouseEnter={() => handleToggle(true)}
                          onMouseLeave={() => handleToggle(false)}
                        >
                          {item.children && (
                            <ul
                              className={clsx(
                                "popper-navigation-list",
                                dense && "dense",
                                "px-0"
                              )}
                            >
                              {item.children.map((_item) => (
                                <FluxNavItem
                                  key={_item.id}
                                  type={`horizontal-${_item.type}`}
                                  item={_item}
                                  nestedLevel={nestedLevel + 1}
                                  dense={dense}
                                />
                              ))}
                            </ul>
                          )}
                        </Paper>
                      </Grow>
                    </div>
                  )
                }
              </Popper>,
              (document as any).querySelector("#root")
            )}
          </>
        </Manager>
      </ul>
    ),
    [
      dense,
      handleToggle,
      item,
      nestedLevel,
      opened,
      props.location.pathname,
      theme.direction,
    ]
  );
}

FluxNavHorizontalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};

FluxNavHorizontalCollapse.defaultProps = {};

const NavHorizontalCollapse = withRouter(memo(FluxNavHorizontalCollapse));

export default NavHorizontalCollapse;

import { RouteConfig } from "app/shared/types";
import PredictWalkthrough from "./predictWalkthrough";

const PredictWalkthroughRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "airflow-calculation",
      element: <PredictWalkthrough />,
    },
  ],
};

export default PredictWalkthroughRouteConfig;

import { Navigate } from "react-router-dom";
import FluxUtils from "@flux/utils";
import { Route } from "app/shared/types";
import LoginConfig from "app/login/LoginConfig";
import FluxLoading from "@flux/core/FluxLoading";
import Error404Page from "app/main/404/Error404Page";
import UsersrouteConfig from "app/main/apps/users/usersRouteConfig";
import DashboardRouteConfig from "app/main/dashboard/DashboardRouteConfig";
import TrainingrouteConfig from "app/main/apps/training/trainingRouteConfig";
import PredictAchRouteConfig from "app/main/apps/predict-ach/predictAchRouteConfig";
import PredictWalkthroughRouteConfig from "app/main/apps/predict-walkthrough/PredictWalkthroughRouteConfig";

const routeConfigs = [
  DashboardRouteConfig,
  LoginConfig,
  PredictAchRouteConfig,
  TrainingrouteConfig,
  UsersrouteConfig,
  PredictWalkthroughRouteConfig,
];

const routes: Route[] = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FluxUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FluxUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: "/",
    element: <Navigate to="airflow-calculation" />,
    permissions: ["sf:logged_in"],
  },
  {
    path: "loading",
    element: <FluxLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;

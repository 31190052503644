import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";

function PredictWalkthroughHead(props) {
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <Typography
          component={motion.span}
          className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold text-black"
        >
          Airflow Calculation{" "}
        </Typography>
      </div>
    </div>
  );
}

export default PredictWalkthroughHead;

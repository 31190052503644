import DashboardIcon from '@mui/icons-material/Dashboard';
import CircleIcon from '@mui/icons-material/Circle';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AppsIcon from '@mui/icons-material/Apps';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import clsx from 'clsx';

const mapIcon = (icon) => {
    switch (icon) {
        case "pulse-icon":
            return <MonitorHeartIcon className={clsx('flux-list-item-icon text-16 shrink-0')}></MonitorHeartIcon>
        case "apps":
            return <AppsIcon className={clsx('flux-list-item-icon text-16 shrink-0')}></AppsIcon>
        case "dashboard":
            return <DashboardIcon className={clsx('flux-list-item-icon text-16 shrink-0')}></DashboardIcon>
        case "star":
            return <StarIcon></StarIcon>
        case "star_border":
            return <StarBorderIcon></StarBorderIcon>
        case "expand_less":
            return <ExpandLessIcon></ExpandLessIcon>
        case "expand_more":
            return <ExpandMoreIcon></ExpandMoreIcon>
        default:
            return <CircleIcon></CircleIcon>
    }
}

export default function FluxIcon({ icon }) {
    return mapIcon(icon);
}
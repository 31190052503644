import FluxScrollbars from "@flux/core/FluxScrollbars";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleQuickPanel } from "./store/stateSlice";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: 280,
  },
}));

function QuickPanel(props) {
  const dispatch = useDispatch();
  const state: any = useSelector(({ quickPanel }: any) => quickPanel?.state);

  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleQuickPanel(null))}
      disableSwipeToOpen
    >
      <FluxScrollbars>
        <Typography>Quick Panel</Typography>
      </FluxScrollbars>
    </StyledSwipeableDrawer>
  );
}

export default memo(QuickPanel);

import { useSelector } from "react-redux";
import FluxScrollbars from "@flux/core/FluxScrollbars";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { selectContrastMainTheme } from "app/store/flux/settingsSlice";
import clsx from "clsx";
import { DefaultTheme } from "@mui/system";

function FluxPageSimpleSidebarContent(props) {
  const theme = useTheme();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  ) as DefaultTheme;

  return (
    <FluxScrollbars enable={props.innerScroll}>
      {props.header && (
        <ThemeProvider theme={contrastTheme}>
          <div
            className={clsx(
              "FluxPageSimple-sidebarHeader",
              props.variant,
              props.sidebarInner && "FluxPageSimple-sidebarHeaderInnerSidebar"
            )}
          >
            {props.header}
          </div>
        </ThemeProvider>
      )}

      {props.content && (
        <div className="FluxPageSimple-sidebarContent">{props.content}</div>
      )}
    </FluxScrollbars>
  );
}

export default FluxPageSimpleSidebarContent;

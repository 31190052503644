import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/flux/messageSlice';

export type TTraining = {
  particle_size: number
  position_of_lights: string
  operating_room_temp: string
  position_of_outlets: string
  ach: number
  name: string
  file: File
}

export type TSelectModel = {
  id: string,
  is_selected: boolean
}


export type TTrainingState = {
  items: TTraining[];
}

export const fetchTraining = createAsyncThunk(
  'training/fetch',
  async () => {
    const response = await axios.get('/training-model');
    return response.data;
  }
)

export const createTraining = createAsyncThunk(
  'training/create',
  async (training: TTraining, { dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('particle_size', training.particle_size.toString());
      formData.append('position_of_lights', training.position_of_lights);
      formData.append('operating_room_temp', training.operating_room_temp);
      formData.append('position_of_outlets', training.position_of_outlets.toString());
      formData.append('ach', training.ach.toString());
      formData.append('name', training.name);
      formData.append('file', training.file);

      const response = await axios.post('/training-model', formData);
      dispatch(fetchTraining())

      dispatch(
        showMessage({
          message: response.data.message,
          variant: 'success',
          autoHideDuration: 2000,
        })
      );

      return response.data.data;
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.detail.error,
          variant: 'error',
          autoHideDuration: 2000,
        })
      );
    }
  }
);

export const selectModel = createAsyncThunk(
  "training/selectModel",
  async (data: TSelectModel, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { id } = data;

    try {
      const response = await axios.post(`/select-model/${id}`);
      dispatch(
        showMessage({
          message: response.data.message,
          variant: 'success',
          autoHideDuration: 2000,
        })
      );

      dispatch(fetchTraining());
      return response.data;
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.detail.error,
          variant: 'error',
          autoHideDuration: 2000,
        })
      );
    }
  }
);

const initialState: TTrainingState = {
  items: [],
};

const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchTraining.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
    [`${createTraining.fulfilled}`]: (state, action) => {
      state.items = action.payload;
    }
  }
}
);

export default trainingSlice.reducer;

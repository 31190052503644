import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import { Box, Button, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Coursel = (props) => {
  const { images, maxSteps, isDone, title, subTitle } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [imageAspectRatio, setImageAspectRatio] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const firstImage = new Image();
    firstImage.src = images[0].url;

    firstImage.onload = () => {
      const aspectRatio: any = firstImage.height / firstImage.width;
      setImageAspectRatio(aspectRatio);
    };
  }, [images]);

  const handleNext = () => {
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if (isDone) {
        navigate("/predict-ach");
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Typography variant="h5" className="mb-4 text-black">
        {title}
      </Typography>

      <Typography variant="body2" className="mb-4 mt-4 text-black">
        <strong>Note: </strong> {subTitle}
      </Typography>

      <div className="leading-5 self-stretch mx-2 mt-2 mb-3 text-black">
        Click Next in lower right corner. Watch:
        <ul className="text-slate-800 leading-5 self-stretch mx-2 mt-2 mb-5 text-black">
          <li>Arrows select examples of the 4 variables,</li>
          <li>Arrow hits Submits,</li>
          <li>Predicted ACH (air changes/hour) appears,</li>
          <li>Accuracy number appears</li>
        </ul>
      </div>

      <div
        style={{
          height: imageAspectRatio
            ? `calc(61vw * ${imageAspectRatio})`
            : "auto",
        }}
      >
        {/* Set the height of the wrapper based on the first image's aspect ratio */}
        <SwipeableViews
          axis={"x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.id}>
              <Typography variant="h6" className="mb-4 text-black">
                {step.text}
              </Typography>
              {Math.abs(activeStep - index) <= 2 ? (
                step.type === "video" ? (
                  <video
                    key={step.id}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      width: "100%",
                      objectFit: "scale-down",
                      aspectRatio: 4 / 2.2,
                    }}
                    src={step.url}
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    loop={false}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      width: "100%",
                      objectFit: "scale-down",
                      aspectRatio: 4 / 2.2,
                    }}
                    src={step.url}
                  />
                )
              ) : null}
            </div>
          ))}
        </SwipeableViews>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            style={{ color: "Black" }}
            disabled={isDone ? false : activeStep === maxSteps - 1}
          >
            {isDone && activeStep === maxSteps - 1 ? "Done" : "Next"}
            {<KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            style={{ color: "Black" }}
            disabled={activeStep === 0}
          >
            {<KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
};

export default Coursel;

import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import * as Yup from "yup";
import { useState } from "react";
import { useAppDispatch } from "app/withAppProviders";
import { createTraining } from "./store/trainingSlice";
import { EDialogType, closeDialog } from "../store/dialogSlice";
import { Switch } from "@mui/material";

function TrainingAddForm() {
  const dispatch = useAppDispatch();
  const [imageUploadData, setImageUploadData] = useState<any>(null);
  const [showOperatingRoomTempOptions, setShowOperatingRoomTempOptions] =
    useState(false);

  const handleImageUpload = (data: any) => {
    setImageUploadData(data);
  };

  const toggleOperatingRoomTempOptions = () => {
    setShowOperatingRoomTempOptions((prevValue) => !prevValue);
  };

  const onSubmit = (w) => {
    w.file = imageUploadData;
    dispatch(createTraining(w));
    dispatch(
      closeDialog({ key: EDialogType.TrainingAdd, title: "Add Training" })
    );
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "name",
        label: "Model Name",
        type: FieldType.Text,
        reduxObjectName: "name",
        validation: Yup.string()
          .required("Please enter Model Name")
          .typeError("Required!"),
      },
      {
        name: "particle_size",
        label: "Particle Size",
        type: FieldType.StaticSelect,
        reduxObjectName: "particle_size",
        validation: Yup.string()
          .required("Please select particle size")
          .typeError("Required!"),
        options: [
          // { id: 3, name: "3: Bacteria" },
          { id: 2.5, name: "2.5: EPA Listed Air Hazard" },
          { id: 1, name: "1: Bacteria" },
          { id: 0.7, name: "0.7: Largest Wildfire particle size" },
          { id: 0.5, name: "0.5: Largest COVID virus size" },
          { id: 0.4, name: "0.4: Smallest Wildfire size" },
          { id: 0.1, name: "0.1: Smallest COVID virus size" },
          // { id: 0.045, name: "0.045: Zika virus size" },
        ],
      },
      {
        name: "position_of_lights",
        label: "Position of Lights",
        type: FieldType.StaticSelect,
        reduxObjectName: "position_of_lights",
        validation: Yup.string()
          .required("Please select position of lights")
          .typeError("Required!"),
        options: [{ id: "head_feet", name: "Head & Feet" }],
      },
      {
        name: "operating_room_temp",
        label: "Operating Room Temperature",
        type: FieldType.StaticSelect,
        reduxObjectName: "operating_room_temp",
        validation: Yup.string()
          .required("Please select operating room temperature")
          .typeError("Required!"),
        options: showOperatingRoomTempOptions
          ? [
              { id: "68", name: "68°F" },
              { id: "69", name: "69°F" },
              { id: "70", name: "70°F" },
              { id: "71", name: "71°F" },
              { id: "72", name: "72°F" },
            ]
          : [
              { id: "68", name: "20°C" },
              { id: "69", name: "20.555°C" },
              { id: "70", name: "21.111°C" },
              { id: "71", name: "21.666°C" },
              { id: "72", name: "22.222°C" },
            ],
      },
      {
        name: "position_of_outlets",
        label: "Position of Outlets",
        type: FieldType.StaticSelect,
        reduxObjectName: "position_of_outlets",
        validation: Yup.string()
          .required("Please select operating room temperature")
          .typeError("Required!"),
        options: [
          { id: "A", name: "Walls Parallel to Patient (Head and Feet) = A" },
          { id: "B", name: "Walls Parallel to patient (Torso) = B" },
          {
            id: "C",
            name: "Walls Perpendicular to Patient (Head and Feet) = C",
          },
        ],
      },
      {
        name: "ach",
        label: "ACH",
        type: FieldType.StaticSelect,
        reduxObjectName: "ach",
        validation: Yup.string()
          .required("Please select ach")
          .typeError("Required!"),
        options: [
          { id: 20, name: "20" },
          { id: 21, name: "21" },
          { id: 22, name: "22" },
          { id: 23, name: "23" },
          { id: 24, name: "24" },
          { id: 25, name: "25" },
          { id: 26, name: "26" },
        ],
      },

      {
        name: "file",
        label: "Upload File",
        type: FieldType.FileUpload,
        reduxObjectName: "fileUpload",
        onChangeHandler: handleImageUpload,
        validation: Yup.string()
          .required("Please select file")
          .typeError("Required!"),
        fileInfoText:
          "The data must be a zip file and there must be only 5 .prof files in the zip file. The system will then extract files as needed to train the model ",
      },
    ],
    resetFormOnSubmit: true,
  };

  return (
    <>
      <div style={{ margin: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <Switch
            checked={showOperatingRoomTempOptions}
            onChange={toggleOperatingRoomTempOptions}
            sx={{
              "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
                color: "black",
              },
              "&.MuiSwitch-root .MuiSwitch-track": {
                backgroundColor: "gray",
              },
            }}
          />
          <label style={{ marginLeft: 8, marginTop: 10 }}>
            {showOperatingRoomTempOptions
              ? "Operating Room Temp (F)"
              : "Operating Room Temp (C)"}
          </label>
        </div>
        <SFForm {...form}></SFForm>
      </div>
    </>
  );
}

export default TrainingAddForm;

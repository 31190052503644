import NavLinkAdapter from "@flux/core/NavLinkAdapter";
import ListSubheader from "@mui/material/ListSubheader";
import { alpha, styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMemo } from "react";
import FluxNavItem from "../../FluxNavItem";

const Root: any = styled(ListSubheader)(
  ({ theme, itempadding, ...props }: any) => ({
    height: 40,
    width: "100%",
    borderRadius: "6px",
    margin: "24px 0 4px 0",
    paddingRight: 12,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    color: alpha(theme.palette.text.primary, 0.7),
    fontWeight: 600,
    letterSpacing: "0.025em",
  })
);

function FluxNavVerticalGroup(props) {
  const { item, nestedLevel, onItemClick } = props;

  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;

  return useMemo(
    () => (
      <>
        <Root
          component={item.url ? NavLinkAdapter : "li"}
          itempadding={itempadding}
          disableSticky
          className={clsx(
            "flux-list-subheader flex items-center",
            !item.url && "cursor-default"
          )}
          onClick={() => onItemClick && onItemClick(item)}
          to={item.url}
          end={item.end}
          role="button"
        >
          <span className="flux-list-subheader-text uppercase text-12">
            {item.title}
          </span>
        </Root>
        {item.children && (
          <>
            {item.children.map((_item) => (
              <FluxNavItem
                key={_item.id}
                type={`vertical-${_item.type}`}
                item={_item}
                nestedLevel={nestedLevel}
                onItemClick={onItemClick}
              />
            ))}
          </>
        )}
      </>
    ),
    [item, itempadding, nestedLevel, onItemClick]
  );
}

FluxNavVerticalGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

FluxNavVerticalGroup.defaultProps = {};

const NavVerticalGroup = FluxNavVerticalGroup;

export default NavVerticalGroup;

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { Card, CardContent, Typography } from "@mui/material";
import { darken, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { submitLogin } from "./store/loginSlice";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import Icon from "@mui/material/Icon";
import * as yup from "yup";

const Root = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${
    theme.palette.primary.dark
  } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
  color: theme.palette.primary.contrastText,

  "& .Login-leftSection": {},

  "& .Login-rightSection": {
    background: `linear-gradient(to right, ${
      theme.palette.primary.dark
    } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

const schema = yup.object().shape({
  username: yup.string().required("Please enter your username"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(5, "Password must have a minimum of 5 characters"),
});

const defaultValues = {
  username: "",
  password: "",
};

interface LoginFormProps {
  handleTabChange: (value: any) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleTabChange }) => {
  const dispatch = useDispatch<any>();

  const login = useSelector((state: any) => state.auth.login);
  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: "manual",
        message: error.message,
      });
    });
  }, [login.errors, setError]);

  function onSubmit(model) {
    dispatch(submitLogin(model));
  }

  return (
    <Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Login-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0"
          square
        >
          <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320 mt-36">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            ></motion.div>
            <form
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-16"
                    type="text"
                    error={!!errors.username}
                    helperText={errors?.username?.message}
                    InputLabelProps={{ style: { color: "black" } }}
                    label="Username"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className="text-20" color="action">
                            user
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-16"
                    label="Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                    InputProps={{
                      className: "pr-2",
                      type: showPassword ? "text" : "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            size="large"
                          >
                            <Icon className="text-20" color="action">
                              {showPassword ? "visibility" : "visibility_off"}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                type="submit"
                variant="contained"
                className="w-full mx-auto mt-16"
                aria-label="LOG IN"
                value="legacy"
                style={{ backgroundColor: "#000", color: "#fff" }}
                disabled={!formState.isValid}
              >
                Sign In
              </Button>
            </form>
          </CardContent>

          <div className="flex flex-col items-center justify-center pb-32">
            <span className="font-normal">Don't have an account?</span>
            <Link
              style={{ color: "#009f6b" }}
              className="font-normal"
              to="/signUp"
            >
              SignUp
            </Link>
          </div>
        </Card>

        <div className="Login-rightSection flex hidden md:flex flex-1 items-center justify-center p-64">
          <div className="max-w-320">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <Typography
                color="inherit"
                className="text-32 sm:text-44 font-semibold leading-tight text-black"
              >
                Welcome to
                <br />
                <span style={{ color: "#009f6b" }}>Airflow SurgicalPrime</span>
                <sup className="text-black">&trade;</sup>
                <br />
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            ></motion.div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
};
export default LoginForm;

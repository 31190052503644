import BrowserRouter from "@flux/core/BrowserRouter";
import FluxAuthorization from "@flux/core/FluxAuthorization";
import FluxLayout from "@flux/core/FluxLayout";
import FluxTheme from "@flux/core/FluxTheme";
import { SnackbarProvider } from "notistack";
import { Auth } from "./auth";
import withAppProviders from "./withAppProviders";
import axios from "axios";
import Init from "./Init";
import "rsuite/dist/rsuite.min.css";
import EnvironmentTag from "./shared/components/EnvironmentTag";

/**
 * Axios HTTP Request defaults
 */
console.log("REACT_APP_PORTAL_BACKEND", process.env.REACT_APP_PORTAL_BACKEND);

axios.defaults.baseURL = process.env.REACT_APP_PORTAL_BACKEND;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common["Content-Type"] = "application/json";

const App = () => {
  return (
    <Auth>
      <BrowserRouter basename="" window="" key="">
        <FluxAuthorization>
          <FluxTheme>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                containerRoot:
                  "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
              }}
            >
              <Init>
                <FluxLayout />
              </Init>
            </SnackbarProvider>
          </FluxTheme>
        </FluxAuthorization>
      </BrowserRouter>
      <EnvironmentTag />
    </Auth>
  );
};

export default withAppProviders(App)();

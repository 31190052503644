import FluxNavigation from "@flux/core/FluxNavigation";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigation } from "app/store/flux/navigationSlice";
import { navbarCloseMobile } from "../../store/flux/navbarSlice";

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  function handleItemClick(item) {
    if (mdDown) {
      dispatch(navbarCloseMobile(null));
    }
  }

  return (
    <FluxNavigation
      className={clsx("navigation", props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);

import FluxIcon from "@flux/core/FluxIcon/FluxIcon";
import NavLinkAdapter from "@flux/core/NavLinkAdapter";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha, styled } from "@mui/material/styles";
import SFIcon from "app/shared/components/SFIcon";
import { ESFIconType } from "app/shared/components/SFIcon/SFIcon";
import PropTypes from "prop-types";
import { useMemo } from "react";
import FluxNavBadge from "../../FluxNavBadge";

const Root: any = styled(ListItem)(({ theme, ...props }: any) => ({
  height: 40,
  width: "100%",
  borderRadius: "6px",
  margin: "0 0 4px 0",
  paddingRight: 12,
  paddingLeft: props.itempadding,
  color: alpha(theme.palette.text.primary, 0.7),
  cursor: "pointer",
  textDecoration: "none!important",
  "&:hover": {
    color: theme.palette.text.primary,
  },
  "&.active": {
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, .05)!important"
        : "rgba(255, 255, 255, .1)!important",
    pointerEvents: "none",
    transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
    "& > .flux-list-item-text-primary": {
      color: "inherit",
    },
    "& > .flux-list-item-icon": {
      color: "inherit",
    },
  },
  "& >.flux-list-item-icon": {
    marginRight: 12,
    color: "inherit",
  },
  "& > .flux-list-item-text": {},
}));

function FluxNavVerticalItem(props) {
  const { item, nestedLevel, onItemClick } = props;

  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;

  return useMemo(
    () => (
      <Root
        button
        component={NavLinkAdapter}
        to={item.url}
        activeClassName="active"
        className="flux-list-item"
        onClick={() => onItemClick && onItemClick(item)}
        end={item.end}
        itempadding={itempadding}
        role="button"
      >
        {item.icon && (
          <SFIcon icon={item.icon} type={ESFIconType.Icon}></SFIcon>
        )}

        <ListItemText
          className="flux-list-item-text"
          primary={item.title}
          classes={{
            primary: "text-13 font-medium flux-list-item-text-primary",
          }}
        />
        {item.badge && <FluxNavBadge badge={item.badge} />}
      </Root>
    ),
    [item, itempadding, onItemClick]
  );
}

FluxNavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

FluxNavVerticalItem.defaultProps = {};

const NavVerticalItem = FluxNavVerticalItem;

export default NavVerticalItem;

import _ from "@lodash";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setDefaultSettings } from "app/store/flux/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { navbarToggle, navbarToggleMobile } from "../../store/flux/navbarSlice";

function NavbarToggleButton(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const settings = useSelector(({ flux }: any) => flux.settings.current);
  const { config } = settings.layout;

  return (
    <IconButton
      className={props.className}
      color="inherit"
      size="small"
      onClick={(ev) => {
        if (mdDown) {
          dispatch(navbarToggleMobile(null));
        } else if (config.navbar.style === "style-2") {
          dispatch(
            setDefaultSettings(
              _.set(
                {},
                "layout.config.navbar.folded",
                !settings.layout.config.navbar.folded
              )
            )
          );
        } else {
          dispatch(navbarToggle(null));
        }
      }}
    >
      {props.children}
    </IconButton>
  );
}

NavbarToggleButton.defaultProps = {
  children: (
    <MenuOpenIcon fontSize="inherit" className="text-16"></MenuOpenIcon>
  ),
};

export default NavbarToggleButton;

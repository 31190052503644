import { ThemeProvider } from "@mui/material/styles";
import { memo, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { selectMainTheme } from "app/store/flux/settingsSlice";

const useEnhancedEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

function FluxTheme(props) {
  const direction = useSelector(
    ({ flux }: any) => flux.settings.defaults.direction
  );
  const mainTheme = useSelector(selectMainTheme);

  useEnhancedEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  // console.warn('FluxTheme:: rendered',mainTheme);
  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
}

export default memo(FluxTheme);

import withRouter from "@flux/core/withRouter";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/withAppProviders";
import { fetchUsers, deleteUser, startEditing } from "./store/usersSlice";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { EDialogType, openDialog } from "../store/dialogSlice";

function UsersTable(props) {
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.users.items);
  const gridRef = useRef<AgGridReact<any>>(null);


  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "id",
      headerName: `ID`,
    },
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "user_type", headerName: "User Type" },
    {
      field: "id",
      cellRenderer: ActionButtonRenderer([
        {
          click: (id) => {
            dispatch(startEditing(id))
            dispatch(openDialog({
              key: EDialogType.UsersAdd,
              title: 'Edit User',
            }));
          },
          title: "Edit",
        },
        {
          click: (id) => {
            dispatch(deleteUser(id));
          },
          title: "Delete",
        },
      ]),
      headerName: 'Actions',
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  const getRowStyle = (params) => {
    const evenColor = "#c7cdd5";
    const oddColor = "#d5c7c7";
    const isEvenRow = params.node.rowIndex % 2 === 0;
    const background = isEvenRow ? evenColor : oddColor;
    return { background };
  };

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowStyle={getRowStyle}
        ></AgGridReact>
      </div>
    </>
  );
}

UsersTable.propTypes = {};

export default withRouter(UsersTable);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showMessage } from "app/store/flux/messageSlice";

export type TPredictAch = {
  particle_size: number;
  position_of_lights: string;
  operating_room_temp: string;
  position_of_outlets: string;
};

export type TPredictAchState = {
  items: TPredictAch[];
  showForm: boolean;
};

export const createPredictAch = createAsyncThunk(
  "predictAch/create",
  async (predictAch: TPredictAch, { dispatch }) => {
    try {
      const response = await axios.post("/testing-model", predictAch);
      dispatch(
        showMessage({
          message: response.data.message,
          variant: "success",
          autoHideDuration: 2000,
        })
      );
      return response.data.data;
    } catch (error: any) {
      dispatch(
        showMessage({
          message: error.response.data.detail.error,
          variant: "error",
          autoHideDuration: 2000,
        })
      );
    }
  }
);

const initialState: TPredictAchState = {
  items: [],
  showForm: false,
};

const predictAchSlice = createSlice({
  name: "predictAch",
  initialState,
  reducers: {
    startShowForm: (state, action) => {
      state.showForm = action.payload;
    },
  },
  extraReducers: {
    [`${createPredictAch.fulfilled}`]: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { startShowForm } = predictAchSlice.actions;
export default predictAchSlice.reducer;

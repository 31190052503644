import FluxPageSimple from "@flux/core/FluxPageSimple";
import { styled } from "@mui/material/styles";
import { useRef } from "react";

const Root = styled(FluxPageSimple)(({ theme }) => ({
  "& .FluxPageSimple-header": {
    minHeight: 160,
    height: 160,
    [theme.breakpoints.up("lg")]: {
      marginRight: 12,
      borderBottomRightRadius: 20,
    },
  },
  "& .FluxPageSimple-toolbar": {
    minHeight: 56,
    height: 56,
    alignItems: "flex-end",
  },
  "& .FluxPageSimple-rightSidebar": {
    width: 288,
    border: 0,
    padding: "12px 0",
  },
  "& .FluxPageSimple-content": {
    // maxHeight: '100%',
    // '& canvas': {
    //     maxHeight: '100%',
    // },
  },
}));

function DashboardApp(props) {
  const pageLayout = useRef(null);

  return (
    <Root
      content={
        <div className="flex shrink-0 flex-col items-center">
        </div>
      }
      ref={pageLayout}
    />
  );
}

export default DashboardApp;

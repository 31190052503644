import Coursel from "./caoursel";

function PredictWalkthroughContent() {
  const imageUrl = process.env.REACT_APP_IMAGES_URL;

  const homeImages = [
    {
      id: 1,
      url: `${imageUrl}/admin/prediction_walkthrough_particle_size.jpg`,
      text: "Select Particle Size",
    },
    {
      id: 2,
      url: `${imageUrl}/admin/prediction_walkthrough_position_of_light.png`,
      text: "Select Position of Light (Default is Head & Feet)",
    },
    {
      id: 3,
      url: `${imageUrl}/admin/prediction_walkthrough_tempreture.jpg`,
      text: "Select Temperature",
    },
    {
      id: 4,
      url: `${imageUrl}/admin/prediction_walkthrough_outlet_A.png`,
      text: "Select Position of Outlet (A) according to OR position",
    },
    {
      id: 5,
      url: `${imageUrl}/admin/prediction_walkthrough_outlet_B.png`,
      text: "Select Position of Outlet (B) according to OR position",
    },
    {
      id: 6,
      url: `${imageUrl}/admin/prediction_walkthrough_outlet_C.png`,
      text: "Select Position of Outlet (C) according to OR position",
    },
    {
      id: 7,
      url: `${imageUrl}/admin/prediction_walkthrough.mov`,
      text: "Predict-ACH",
      type: "video",
    },
  ];

  return (
    <>
      <div
        style={{
          marginLeft: "15%",
          marginRight: "15%",
          marginBottom: "5%",
          marginTop: "5%",
        }}
      >
        <Coursel
          images={homeImages}
          maxSteps={homeImages.length}
          isDone={true}
          title={"Airflow Calculation"}
          subTitle={
            "After you select the four key variables (Particle Size, Position of Lights, Operating Room Temperature, and Position of Outlets), and hit Submit, the Dataset uploads to an AI platform, optimizes the OR's airflow, and shows Predicted ACH (air changes per hour) and the Accuracy of this result."
          }
        />{" "}
      </div>
    </>
  );
}

export default PredictWalkthroughContent;

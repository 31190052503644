import { FC } from "react";
import { useSelector } from "react-redux";
import FluxUtils from "@flux/utils";

type CanAccessType = {
  children: JSX.Element;
  permissions: string[];
};

const CanAccess: FC<CanAccessType> = ({ children, permissions }) => {
  const userPermissions =
    useSelector(({ auth }: any) => auth.user.permissions) || [];
  const accessGranted = FluxUtils.hasPermission(permissions, userPermissions);

  return (accessGranted || !permissions.length) && children;
};

export default CanAccess;

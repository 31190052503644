const fluxDark = {
  50: "#fff",
  100: "#bec1c5",
  200: "#fff",
  300: "#666d78",
  400: "#fff",
  500: "#000",
  600: "#fff",
  700: "#fff",
  800: "#fff",
  900: "#0d121b",
  A100: "#5d8eff",
  A200: "#2a6aff",
  A400: "#004af6",
  A700: "#fff",
  contrastDefaultColor: "light",
};

export default fluxDark;

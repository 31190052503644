import FluxScrollbars from "@flux/core/FluxScrollbars";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import Hidden from "@mui/material/Hidden";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import { memo, useState } from "react";

const Root: any = styled("div")(({ theme }) => ({
  "& .FluxSidePanel-paper": {
    display: "flex",
    width: 56,
    transition: theme.transitions.create(["transform", "width", "min-width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    paddingBottom: 64,
    height: "100%",
    maxHeight: "100vh",
    position: "sticky",
    top: 0,
    zIndex: 999,
    "&.left": {
      "& .FluxSidePanel-buttonWrapper": {
        left: 0,
        right: "auto",
      },
      "& .FluxSidePanel-buttonIcon": {
        transform: "rotate(0deg)",
      },
    },
    "&.right": {
      "& .FluxSidePanel-buttonWrapper": {
        right: 0,
        left: "auto",
      },
      "& .FluxSidePanel-buttonIcon": {
        transform: "rotate(-180deg)",
      },
    },
    "&.closed": {
      [theme.breakpoints.up("lg")]: {
        width: 0,
      },
      "&.left": {
        "& .FluxSidePanel-buttonWrapper": {
          justifyContent: "start",
        },
        "& .FluxSidePanel-button": {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          paddingLeft: 4,
        },
        "& .FluxSidePanel-buttonIcon": {
          transform: "rotate(-180deg)",
        },
      },
      "&.right": {
        "& .FluxSidePanel-buttonWrapper": {
          justifyContent: "flex-end",
        },
        "& .FluxSidePanel-button": {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          paddingRight: 4,
        },
        "& .FluxSidePanel-buttonIcon": {
          transform: "rotate(0deg)",
        },
      },
      "& .FluxSidePanel-buttonWrapper": {
        width: "auto",
      },
      "& .FluxSidePanel-button": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 38,
        transition: theme.transitions.create(
          [
            "background-color",
            "border-radius",
            "width",
            "min-width",
            "padding",
          ],
          {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }
        ),
        width: 24,
        "&:hover": {
          width: 52,
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
      "& .FluxSidePanel-content": {
        opacity: 0,
      },
    },
  },

  "& .FluxSidePanel-content": {
    overflow: "hidden",
    opacity: 1,
    transition: theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  },

  "& .FluxSidePanel-buttonWrapper": {
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0",
    width: "100%",
    minWidth: 56,
  },

  "& .FluxSidePanel-button": {
    padding: 8,
    width: 40,
    height: 40,
  },

  "& .FluxSidePanel-buttonIcon": {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  },

  "& .FluxSidePanel-mobileButton": {
    height: 40,
    position: "absolute",
    zIndex: 99,
    bottom: 12,
    width: 24,
    borderRadius: 38,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(
      ["background-color", "border-radius", "width", "min-width", "padding"],
      {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
      }
    ),
    "&:hover": {
      width: 52,
      paddingLeft: 8,
      paddingRight: 8,
    },
    "&.left": {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      paddingLeft: 4,
      left: 0,
    },

    "&.right": {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingRight: 4,
      right: 0,
      "& .FluxSidePanel-buttonIcon": {
        transform: "rotate(-180deg)",
      },
    },
  },
}));

function FluxSidePanel(props) {
  const [opened, setOpened] = useState(props.opened);
  const [mobileOpen, setMobileOpen] = useState(false);

  function toggleOpened() {
    setOpened(!opened);
  }

  function toggleMobileDrawer() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <Root>
      <Hidden lgDown>
        <Paper
          className={clsx(
            "FluxSidePanel-paper",
            props.className,
            opened ? "opened" : "closed",
            props.position,
            "shadow-lg"
          )}
          square
        >
          <FluxScrollbars className={clsx("content", "FluxSidePanel-content")}>
            {props.children}
          </FluxScrollbars>

          <div className="FluxSidePanel-buttonWrapper">
            <Tooltip
              title="Toggle side panel"
              placement={props.position === "left" ? "right" : "right"}
            >
              <IconButton
                className="FluxSidePanel-button"
                onClick={toggleOpened}
                disableRipple
                size="large"
              >
                <Icon className="FluxSidePanel-buttonIcon">
                  keyboard_arrow_left
                </Icon>
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </Hidden>
      <Hidden lgUp>
        <SwipeableDrawer
          classes={{
            paper: clsx("FluxSidePanel-paper", props.className),
          }}
          anchor={props.position}
          open={mobileOpen}
          onOpen={(ev) => {}}
          onClose={toggleMobileDrawer}
          disableSwipeToOpen
        >
          <FluxScrollbars className={clsx("content", "FluxSidePanel-content")}>
            {props.children}
          </FluxScrollbars>
        </SwipeableDrawer>

        <Tooltip
          title="Hide side panel"
          placement={props.position === "left" ? "right" : "right"}
        >
          <Fab
            className={clsx("FluxSidePanel-mobileButton", props.position)}
            onClick={toggleMobileDrawer}
            disableRipple
          >
            <Icon className="FluxSidePanel-buttonIcon" color="action">
              keyboard_arrow_right
            </Icon>
          </Fab>
        </Tooltip>
      </Hidden>
    </Root>
  );
}

FluxSidePanel.propTypes = {};
FluxSidePanel.defaultProps = {
  position: "left",
  opened: true,
};

export default memo(FluxSidePanel);
